import './Aplicativo.css';
import { Helmet } from 'react-helmet';

import usePageTracking from '../../../hooks/usePageTracking';


import applestore from "../../../assets/img-main/appsteps/apple.png";
import playstore from "../../../assets/img-main/appsteps/playstore.png";

import banner_app_mobile from "../../../assets/img-main/appsteps/banner_app_mobile.png";
import banner_app from "../../../assets/img-main/appsteps/banner_app.png";

const Aplicativo = () => {

    usePageTracking();

    const images = [
        { small: banner_app_mobile, large: banner_app }
    ]
    return (
        <section className="aplicativo">
            <Helmet>
                <title>Aplicativo Trasmontano | Trasmontano Saúde</title>
                <meta name="description"
                    content="Descubra a praticidade da carteirinha Trasmontano Saúde agora no seu celular. Acesse seu cartão digital, rede credenciada, guias, boletos e muito mais pelo aplicativo Trasmontano Saúde." />
            </Helmet>

            <div className="aplicativo_apresentacao">

                <div className='aplicativo_titulo'>

                    <div className='aplicativo_titulo-elementos'>

                        {images.map((image, index) => (
                            <picture key={index}>
                                <source media="(min-width: 768px)" srcSet={image.large} />
                                <source media="(max-width: 767px)" srcSet={image.small} />
                                <img
                                    src={image.large}
                                    alt={`Slide ${index + 1}`}
                                />
                            </picture>
                        ))}

                    </div>

                    <div className='app-description'>
                        <p className='app-description-text'>
                            Acesse o aplicativo exclusivo <strong>Trasmontano Associado</strong> e tenha acesso a todas as informações e serviços do seu plano de saúde de forma simples e prática no dia a dia. Confira alguns dos recursos disponíveis:
                        </p>

                        <div className='app-benefits'>
                            <div className='app-benefits-item'>
                                <i class="fa-solid fa-laptop-medical"></i>
                                <p>Teleconsulta</p>
                            </div>
                            <div className='app-benefits-item'>
                                <i class="fa-solid fa-barcode"></i>
                                <p>Emissão de 2ª via de boleto</p>
                            </div>
                            <div className='app-benefits-item'>
                                <i class="fa-solid fa-file-medical"></i>
                                <p>Resultados de exames </p>
                            </div>
                            <div className='app-benefits-item'>
                                <i class="fa-solid fa-calendar-plus"></i>
                                <p>Agendamento de consultas e exames nas unidades ambulatoriais</p>
                            </div>
                            <div className='app-benefits-item'>
                                <i class="fa-solid fa-address-card"></i>
                                <p>Carteirinha Online</p>
                            </div>
                            <div className='app-benefits-item'>
                                <i class="fa-solid fa-book-medical"></i>
                                <p>Visualização do histórico de atendimento</p>
                            </div>
                        </div>
                    </div>

                </div>

                <div className='container-download_botoes'>
                    <p>
                        Clique nos botões abaixo para baixar o nosso app.
                    </p>

                    <div className='download_botoes'>

                        <a
                            href="https://play.google.com/store/apps/details?id=br.com.trasmontano.associadotrasmontanosaude"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img
                                className='aplicativo_download'
                                src={playstore}
                                alt="Disponível na Play Store"
                            />
                        </a>

                        <a
                            href="https://apps.apple.com/tr/app/trasmontano-associado/id1468500523"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img
                                className='aplicativo_download'
                                src={applestore}
                                alt="Disponível na Apple Store"
                            />
                        </a>

                    </div>

                </div>

            </div>

            {/* <div className='aplicativo_steps-descricao'>

                <div className='aplicativo_steps'>

                    <div className='aplicativo_descricao'>

                        <h3>
                            A praticidade da carteirinha <strong>GoCare Saúde</strong> agora no seu celular!
                        </h3>

                        <div className="div-separate-app">
                            <span className="style-div-separate-app"></span>
                        </div>

                        <p>
                            <strong>Cartão GoCare: </strong>Agora na tela do seu celular;
                        </p>
                        <p>
                            <strong>Rede: </strong>Acesso a sua rede credenciada;
                        </p>
                        <p>
                            <strong>Guias: </strong>Acompanhamento das suas guias;
                        </p>
                        <p>
                            <strong>Boleto: </strong>Acesso ao boleto para planos pessoa física;
                        </p>
                        <p>
                            <strong>PIN-SS Comportamento Cadastral: </strong>Informações sobre seus dados cadastrais e do plano contratado;
                        </p>
                        <p>
                            <strong>PIN-SS Comportamento de Utilização: </strong>Informações sobre suas consultas, exames, terapias e internação.
                        </p>

                    </div>

                    <div className='aplicativo_associado'>
                        <img
                            src={step02}
                            alt="Passo a passo 2"
                        />
                    </div>

                </div>

                <div className='aplicativo_steps'>

                    <div className='aplicativo_associado'>
                        <img
                            src={step03}
                            alt="Passo a passo 3"
                        />
                    </div>

                    <div className='aplicativo_descricao'>
                        <p>
                            Para <strong>o primeiro acesso no app</strong>, insira apenas o seu CPF e clique em <strong>"Cadastrar conta ou nova senha"</strong>.
                        </p>
                    </div>

                </div>

                <div className='aplicativo_steps'>

                    <div className='aplicativo_descricao'>
                        <p>
                            Agora, <strong>digite sua data de nascimento</strong> e <strong>crie uma senha</strong>. Em seguida, clique em <strong>"Cadastrar nova senha"</strong>.
                        </p>
                    </div>

                    <div className='aplicativo_associado'>
                        <img
                            src={step04}
                            alt="Passo a passo 4"
                        />
                    </div>

                </div>

                <div className='aplicativo_steps'>

                    <div className='aplicativo_associado'>
                        <img
                            src={step05}
                            alt="Passo a passo 5"
                        />
                    </div>

                    <div className='aplicativo_descricao'>
                        <p>
                            <strong>Após inserir sua senha</strong>, você receberá a mensagem: <strong>"Senha cadastrada com sucesso"</strong>.
                        </p>
                    </div>

                </div>

                <div className='aplicativo_steps'>

                    <div className='aplicativo_descricao'>
                        <h4>
                            <strong>Pronto!</strong>
                        </h4>
                        <p>Todas as informações que você precisa estarão disponíveis.</p>

                        <div className="div-separate-app">
                            <span className="style-div-separate-app"></span>
                        </div>

                        <h5>
                            <strong>Dicas:</strong>
                        </h5>
                        <ul>
                            <li>
                                Se o beneficiário for o responsável financeiro e também o titular do plano, ele poderá visualizar todas as informações do plano e dos dependentes.
                            </li>

                            <li>
                                Se o beneficiário for apenas o responsável financeiro, o cadastro deve ser realizado com o CPF dos dependentes.
                            </li>

                            <li>
                                Quando desejar verificar dois cadastros no mesmo celular, é necessário, após o primeiro acesso, usar o comando "Sair" nas configurações para incluir o outro CPF para consulta.
                            </li>
                        </ul>
                    </div>

                    <div className='aplicativo_associado'>
                        <img
                            src={step06}
                            alt="Passo a passo 6"
                        />
                    </div>

                </div>

                <div className='aplicativo_steps'>

                    <div className='aplicativo_associado'>
                        <img
                            src={step07}
                            alt="Passo a passo 7"
                        />
                    </div>

                    <div className='aplicativo_descricao'>
                        <p>
                            Para verificar <strong>dois cadastros no mesmo celular</strong>, é necessário acionar o comando <strong>"Sair"</strong> nas configurações após o primeiro acesso. Em seguida, <strong>insira o outro CPF</strong> para uma nova consulta e siga o passo a passo novamente.
                        </p>
                    </div>

                </div>
            </div> */}
            
        </section >
    )
}

export default Aplicativo;