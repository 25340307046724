import React from 'react';
import { Helmet } from 'react-helmet';
import "./NotFound.css"

const NotFound = () => {
    return (
        <div className="page__error">

            <Helmet>
                <title>Página Não Encontrada | Trasmontano Saúde</title>
                <meta name="description" content="A página que você está procurando não existe. Volte para a página principal da Trasmontano Saúde e descubra nossos planos de saúde com cobertura completa na Baixada Santista." />
            </Helmet>

            <h2><span>404</span><br />- Página Não Encontrada -</h2>

            <div class="div-separate">
                <span class="style-div-separate"></span>
            </div>

            <p>Desculpe, a página que você está procurando não existe.</p>
            <p><a href="/">Voltar para a página principal</a></p>

        </div>
    );
};

export default NotFound;
