

import "./Abrangencia.css";
import cityicon from "../../../../assets/img-main/cityicon.png"

const Abrangencia = () => {
    return (
        <div className="abrangencia__container">

            <div className="abrangencia__titulo">
                <h2>
                    ÁREA DE ABRANGÊNCIA
                </h2>
            </div>

            <div className="cidades">
                <div className="cidades__item">
                    <img
                        src={cityicon}
                        alt="Ícone de localização de uma cidade"
                    />
                    <p>
                        ABCDMRR
                    </p>
                </div>

                <div className="cidades__item">
                    <img
                        src={cityicon}
                        alt="Ícone de localização de uma cidade"
                    />
                    <p>
                        Alto Tietê
                    </p>
                </div>

                <div className="cidades__item">
                    <img
                        src={cityicon}
                        alt="Ícone de localização de uma cidade"
                    />
                    <p>
                       SP Capital
                    </p>
                </div>

                <div className="cidades__item">
                    <img
                        src={cityicon}
                        alt="Ícone de localização de uma cidade"
                    />
                    <p>
                        Grande SP
                    </p>
                </div>

                <div className="cidades__item">
                    <img
                        src={cityicon}
                        alt="Ícone de localização de uma cidade"
                    />
                    <p>
                        Litoral
                    </p>
                </div>

            </div>
        </div>
    )
}

export default Abrangencia;