import './Mission.css';

import eyeicon from "../../../../assets/img-main/eye.png"
import targeticon from "../../../../assets/img-main/target.png"
import valueicon from "../../../../assets/img-main/value.png"

const Mission = () => {
    return (
        <div className="valores__info">
            <ul className="valores__info-list">

                <li className="valores__info-item">
                    <div className="info-item-titulo">
                        <img
                            src={targeticon}
                            alt="ícone de alvo/foco/missão"
                        />
                        <h4>
                            Nossa Missão
                        </h4>
                    </div>
                    <p>
                        Prestar assistência médica de excelência, promovendo a saúde de maneira integral e contribuindo para uma melhor qualidade de vida dos nossos associados.
                    </p>
                </li>

                <li className="valores__info-item">
                    <div className="info-item-titulo">
                        <img
                            src={valueicon}
                            alt="ícone de valores da empresa"
                        />
                        <h4>
                            Nossos Valores
                        </h4>
                    </div>
                    <p>
                        Respeito à vida humana; Ética e responsabilidade profissional e social; Compromisso com a promoção da saúde em todas as áreas; Foco em benefícios mútuos e coletivos.
                    </p>
                </li>

                <li className="valores__info-item">
                    <div className="info-item-titulo">
                        <img
                            src={eyeicon}
                            alt="Ícone de visão da empresa"
                        />
                        <h4>
                            Nossa Visão
                        </h4>
                    </div>
                    <p>
                        Crescer de maneira sustentável, consolidando-se como uma instituição cada vez mais sólida, segura e confiável, em benefício de nossos associados e parceiros
                    </p>
                </li>

            </ul>

        </div>
    )
}

export default Mission;