import './Home.css';
import { Helmet } from 'react-helmet';

import Carousel from './carousel/Carousel.js';
import BenefitsCarousel from './benefits/Benefits.js';
import usePageTracking from '../../../hooks/usePageTracking.js';

const Home = () => {

    usePageTracking();

    return (
        <div className='home'>

            <Helmet>
                <title>Trasmontano Saúde</title>
                <meta name="description"
                    content="Trasmontano, referência em atendimento humanizado e serviços médicos de excelência. Contrate online e garanta saúde e bem-estar com facilidade e confiança, além de uma ampla cobertura." />
            </Helmet>


            <Carousel />
            <BenefitsCarousel />

        </div>
    )
}

export default Home;