
import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import './Benefits.css';

import benefits1 from '../../../../assets/img-main/benefits/benefits1.png';
import benefits2 from '../../../../assets/img-main/benefits/benefits2.png';
import benefits3 from '../../../../assets/img-main/benefits/benefits3.png';
import benefits4 from '../../../../assets/img-main/benefits/benefits4.png';
import benefits5 from '../../../../assets/img-main/benefits/benefits5.png';
import benefits6 from '../../../../assets/img-main/benefits/benefits6.png';
import benefits7 from '../../../../assets/img-main/benefits/benefits7.png';
import benefits8 from '../../../../assets/img-main/benefits/benefits8.png';
import benefits9 from '../../../../assets/img-main/benefits/benefits9.png';
import benefits10 from '../../../../assets/img-main/benefits/benefits10.png';
import benefits11 from '../../../../assets/img-main/benefits/benefits11.png';
import benefits12 from '../../../../assets/img-main/benefits/benefits12.png';
import benefits13 from '../../../../assets/img-main/benefits/benefits13.png';
import benefits14 from '../../../../assets/img-main/benefits/benefits14.png';
import benefits15 from '../../../../assets/img-main/benefits/benefits15.png';
import benefits16 from '../../../../assets/img-main/benefits/benefits16.png';

const BenefitsCarousel = () => {
    const benefits = [
        benefits1,
        benefits2,
        benefits3,
        benefits4,
        benefits5,
        benefits6,
        benefits7,
        benefits8,
        benefits9,
        benefits10,
        benefits11,
        benefits12,
        benefits13,
        benefits14,
        benefits15,
        benefits16
    ];

    return (
        <section className="benefits">

            <Swiper
                modules={[Autoplay, Navigation]}
                spaceBetween={0}
                slidesPerView={2}
                loop={true}
                autoplay={{ delay: 2000 }}
                breakpoints={{
                    360: {
                        slidesPerView: 2,
                    },
                    768: {
                        slidesPerView: 3,
                    },
                    1025: {
                        slidesPerView: 4,
                    },
                    1440: {
                        slidesPerView: 5,
                    }
                }}
                navigation={true}
                className="custom-swiper"
            >
                {benefits.map((benefit, index) => (
                    <SwiperSlide key={index}>
                        <img
                            src={benefit}
                            alt={`Slide Benefícios ${index + 1}`}
                        />
                    </SwiperSlide>
                ))}
            </Swiper>

            <div className="benefits__title">
                <h1>Por que escolher  <br />a<span> Trasmontano?</span></h1>
                <p className='benefits__conclusao'>
                    Quando se trata de proteger a saúde de quem você ama, a escolha de um plano de saúde é crucial. A Trasmontano oferece muito mais do que cobertura médica; é a garantia de cuidado personalizado, confiança e bem-estar contínuo. Com um atendimento de excelência e uma rede preparada para atender às mais variadas necessidades, a Trasmontano é a parceira ideal para cuidar da sua saúde e da sua família.
                </p>
            </div>



            <div className="benefits___container">

                <div className="benefits__content benefits_labs">
                    <div></div>
                    <div className="benefits-text">
                        <h3>PRINCIPAIS LABORATÓRIOS CREDENCIADOS</h3>
                        <p>
                            A Trasmontano conta com uma rede de laboratórios de referência, incluindo o Femme, Eurofins - Pasteur, A+ Medicina Diagnóstica, SCOPP Diagnósticos, Labi Exames, Instituto de Análises Clínicas de Santos (IACS) e TECNOLAB. Esses laboratórios garantem serviços de alta qualidade e precisão nos exames, oferecendo praticidade e confiança para os beneficiários.
                        </p>
                    </div>
                </div>


                <div className="benefits__content benefits_amplarede">

                    <div className="benefits-text">
                        <h3>HOSPITAL PRÓPRIO E REDE CREDENCIADA</h3>
                        <p>
                            Além da rede credenciada, a Trasmontano conta com seu próprio hospital, oferecendo um atendimento mais personalizado e ágil aos seus beneficiários. A rede inclui hospitais e clínicas de alta qualidade, como o renomado Hospital IGESP, além de diversos centros clínicos e consultórios espalhados pela Capital de SP, Grande SP, Baixada Santista e outras regiões.
                        </p>
                    </div>
                    <div></div>
                </div>

                <div className="benefits__content benefits_preco">
                    <div></div>
                    <div className="benefits-text">
                        <h3>PLANOS ACESSÍVEIS COM DIFERENTES OPÇÕES DE COBERTURA</h3>
                        <p>
                            A operadora disponibiliza uma variedade de planos com coberturas regionais, permitindo que os clientes escolham o plano que melhor se adapta às suas necessidades, com opções de enfermaria ou apartamento​.
                        </p>
                    </div>

                </div>

                <div className="benefits__content benefits_reclame">
                    <div className="benefits-text">
                        <h3>SATISFAÇÃO DO CLIENTE</h3>
                        <p>
                            A Trasmontano tem uma boa reputação entre os clientes, refletida em sua nota de 8.5 no Reclame Aqui, e também se destaca no Índice de Desempenho da Saúde Suplementar (IDSS), que avalia diversos aspectos de sua operação, como qualidade de atenção e sustentabilidade.
                        </p>
                    </div>
                    <div></div>
                </div>

            </div>
            <p className='benefits__conclusao'>
                Optar pela Trasmontano é uma escolha inteligente para quem busca um plano de saúde abrangente, acessível e confiável. A operadora oferece uma cobertura completa que inclui atendimentos para urgências e emergências, além de serviços médicos como consultas e exames com ampla rede credenciada, incluindo hospitais próprios de excelência, como o Hospital IGESP. Com programas de promoção à saúde e coleta domiciliar, a Trasmontano também se destaca pelo atendimento personalizado, garantindo praticidade e cuidado com a saúde de seus beneficiários.<br />
                Tudo isso aliado a um custo competitivo que se ajusta ao seu orçamento.
            </p>
            {/* <h3><span>SUA SAÚDE</span> É UM PLANO DE AGORA!</h3> */}
        </section>
    );
};

export default BenefitsCarousel;
