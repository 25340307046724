import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay, Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import './Carousel.css';


import banner_hospitais from '../../../../assets/img-main/carousel/banner_hospitais.png';
import banner_hospitais_mobile from '../../../../assets/img-main/carousel/banner_hospitais_mobile.png';
import banner_senior from '../../../../assets/img-main/carousel/banner_senior.png';
import banner_senior_mobile from '../../../../assets/img-main/carousel/banner_senior_mobile.png';
import banner_empresarial from '../../../../assets/img-main/carousel/banner_empresarial.png';
import banner_empresarial_mobile from '../../../../assets/img-main/carousel/banner_empresarial_mobile.png';
import banner_smart from '../../../../assets/img-main/carousel/banner_smart.png';
import banner_smart_mobile from '../../../../assets/img-main/carousel/banner_smart_mobile.png';
import banner_life from '../../../../assets/img-main/carousel/banner_life.png';
import banner_life_mobile from '../../../../assets/img-main/carousel/banner_life_mobile.png';
import banner_farma from '../../../../assets/img-main/carousel/banner_farma.png';
import banner_farma_mobile from '../../../../assets/img-main/carousel/banner_farma_mobile.png';
import banner_labs from '../../../../assets/img-main/carousel/banner_labs.png';
import banner_labs_mobile from '../../../../assets/img-main/carousel/banner_labs_mobile.png';

const Carousel = () => {
  const images = [
    { small: banner_hospitais_mobile, large: banner_hospitais},
    { small: banner_senior_mobile, large: banner_senior },
    { small: banner_empresarial_mobile, large: banner_empresarial },
    { small: banner_smart_mobile, large: banner_smart },
    { small: banner_life_mobile, large: banner_life },
    { small: banner_labs_mobile, large: banner_labs},
    { small: banner_farma_mobile, large: banner_farma }
  ];

  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/cliente');
  };

  return (
    <section className="carousel-container">
      <Swiper
        modules={[Pagination, Autoplay, Navigation]}
        spaceBetween={50}
        slidesPerView={1}
        loop={true}
        autoplay={{ delay: 7000 }}
        navigation={true}
        pagination={{ clickable: true }}
        className="custom-swiper"
      >
        {images.map((image, index) => (
          <SwiperSlide key={index}>
            <picture onClick={handleClick}>
              <source media="(min-width: 768px)" srcSet={image.large} />
              <source media="(max-width: 767px)" srcSet={image.small} />
              <img
                src={image.large}
                alt={`Slide ${index + 1}`}
              />
            </picture>
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  );
};

export default Carousel;
