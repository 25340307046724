import './AboutInfo.css';
import unidadetrasmontano from '../../../../assets/img-main/unidadetrasmontano.png'

const AboutInfo = () => {
    return (
        <div className='about__info'>
            <div className='about__info__text'>
                <h2 className='about__title'>
                    A <span>Trasmontano Saúde</span> 
                </h2>
                <p className='about__paragraph'>
                    Em um mercado cada vez mais dinâmico e competitivo, é fundamental estar preparado para enfrentar desafios e identificar novas oportunidades. Por isso, formamos equipes profissionais dedicadas e qualificadas, garantindo a qualidade dos serviços e reafirmando nosso compromisso com o crescimento e a solidez da marca Trasmontano.
                </p>
                <p className='about__paragraph'>
                    Atualmente, somos um grupo especializado em saúde, com ampla atuação na gestão de planos, serviços médicos hospitalares e também na área de educação em saúde.
                </p>

                <h3 className='about__subtitle'>
                    Mais de <span>90 anos de história dedicados à saúde e ao bem-estar</span> dos nossos associados!
                </h3>
            </div>
            <div>
                <img
                    src={unidadetrasmontano}
                    alt="Unidade Trasmontano"
                />
            </div>
        </div>
    );
}

export default AboutInfo;
