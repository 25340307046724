import './CardPlanos.css';
import btnwpp from '../../../../assets/img-main/btn-plan-wpp.png';
import btndownload from '../../../../assets/img-main/downloadicon.png';

const CardPlanos = () => {
    return (
        <section className="card-planos-section">
            <div className="card-planos-container">

                <div className="card-planos__adesao">

                    <div className="card-planos__text">
                        <div>
                            <h3>Linha <strong>Smart</strong><br />
                                Plano de Saúde <strong>Individual e Familiar</strong></h3>

                            <h4>Ambulatorial e Hospitalar <strong>- SEM OBSTETRÍCIA</strong> + Odontológica</h4>
                        </div>

                        <p>
                            O <strong>Plano Smart Individual</strong> oferece uma experiência médica personalizada, permitindo que você escolha profissionais e serviços conforme suas preferências e necessidades específicas. Já o <strong>Plano Smart Familiar</strong> proporciona cobertura completa para toda a sua família em um único contrato, com vantagens exclusivas e acesso facilitado a uma ampla rede de atendimento.
                        </p>

                        <div className="div-separate">
                            <span className="style-div-separate"></span>
                        </div>
                    </div>

                    <div className="card-planos__cards">
                        <div className="card bg-plans adesao-card">
                            <h2 className="plan-name">SMART SP<br />
                                <span>Linha <strong>Smart</strong></span></h2>
                            <p className="plan-starting-price">a partir de:</p>
                            <h2 className="price">R$ 280,14</h2>
                            <p className="age-group"><strong>De 00 a 18 anos</strong></p>
                            <p className="plan__room"><strong>Enfermaria</strong><br />
                                (Em caso de Internação)</p>
                            <div className="hospitals">
                                <p>Hospital IGESP Unidade Paulista e Santana</p>
                                <p>Hospital Santa Virginia</p>
                                <p>Day Hospital de Ermelino Matarazzo</p>
                                <p>Hospital São Miguel</p>
                                <p>Santa Casa de Santo Amaro</p>
                                <p>E muito mais!</p>
                            </div>

                            <div className="quote-button-container">

                                <a
                                    className="quote-button"
                                    href="/rede"
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    <p className="quote-button-text">
                                        Rede de Atendimento</p>
                                    <img
                                        src={btndownload}
                                        alt="Ícone de Download">
                                    </img>
                                </a>

                                <a
                                    className="quote-button"
                                    href="https://api.whatsapp.com/send?phone=5513981260507&text=Olá,%20estou%20entrando%20em%20contato%20para%20obter%20informações%20sobre%20a%20Trasmontano%20Saúde."
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    <p className="quote-button-text">
                                        Quero uma cotação </p>
                                    <img
                                        src={btnwpp}
                                        alt="Ícone do WhatsApp">
                                    </img>
                                </a>
                            </div>
                        </div>

                        <div className="card bg-plans adesao-card">
                            <h2 className="plan-name">SMART 200<br />
                                <span>Linha <strong>Smart</strong></span></h2>
                            <p className="plan-starting-price">a partir de:</p>
                            <h2 className="price">R$ 313,54</h2>
                            <p className="age-group"><strong>De 00 a 18 anos</strong></p>
                            <p className="plan__room"><strong>Enfermaria</strong><br />
                                (Em caso de Internação)</p>
                            <div className="hospitals">
                                <p>Hospital IGESP Unidade Paulista, Santana e Litoral</p>
                                <p>Hospital Santa Virginia</p>
                                <p>Hospital do Guarujá</p>
                                <p>Santa Casa de Santo Amaro</p>
                                <p>Hospital Beneficência Portuguesa de Santos</p>
                                <p>E muito mais!</p>
                            </div>

                            <div className="quote-button-container">

                                <a
                                    className="quote-button"
                                    href="/rede"
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    <p className="quote-button-text">
                                        Rede de Atendimento</p>
                                    <img
                                        src={btndownload}
                                        alt="Ícone de Download">
                                    </img>
                                </a>

                                <a
                                    className="quote-button"
                                    href="https://api.whatsapp.com/send?phone=5513981260507&text=Olá,%20estou%20entrando%20em%20contato%20para%20obter%20informações%20sobre%20a%20Trasmontano%20Saúde."
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    <p className="quote-button-text">
                                        Quero uma cotação </p>
                                    <img
                                        src={btnwpp}
                                        alt="Ícone do WhatsApp">
                                    </img>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="card-planos__empresa">
                    <div className="card-planos__text card-planos__empresa__text">
                        <div>
                            <h3>
                                Linha <strong>Life</strong><br />
                                Plano de Saúde <strong>Individual e Familiar</strong>
                            </h3>
                            <h4>Ambulatorial e Hospitalar <strong>+ COM OBSTETRíCIA</strong></h4>
                        </div>

                        <p>
                            <strong>Desenvolvido especialmente para atender você e sua família com eficiência e cuidado.</strong> A Linha Life combina cobertura abrangente com acesso a uma ampla rede de profissionais e serviços, oferecendo planos que garantem tranquilidade e bem-estar. Com opções personalizadas para diferentes perfis, você pode contar com condições especiais para atender a todas as suas necessidades de saúde.
                        </p>

                        <div className="div-separate">
                            <span className="style-div-separate"></span>
                        </div>
                    </div>

                    <div className="card-planos__cards">
                        <div className="card bg-plans empresarial-card">
                            <h2 className="plan-name">LIFE 100<br />
                                <span>Linha <strong>Life Litoral</strong></span></h2>
                            <p className="plan-starting-price">a partir de:</p>
                            <h2 className="price">R$ 328,07</h2>
                            <p className="age-group"><strong>De 00 a 18 anos</strong></p>
                            <p className="plan__room"><strong>Enfermaria</strong><br />
                                (Em caso de Internação)</p>
                            <div className="hospitals">
                                <p>Hospital IGESP Unidade Litoral</p>
                                <p>Hospital Beneficência Portuguesa de Santos</p>
                                <p>Santa Casa de Santos</p>
                                <p>Hospital do Guarujá</p>
                                <p>APAS Hospital Santo Expedito</p>
                                <p>E muito mais!</p>
                            </div>
                            <div className="quote-button-container">

                                <a
                                    className="quote-button"
                                    href="/rede"
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    <p className="quote-button-text">
                                        Rede de Atendimento</p>
                                    <img
                                        src={btndownload}
                                        alt="Ícone de Download">
                                    </img>
                                </a>

                                <a
                                    className="quote-button"
                                    href="https://api.whatsapp.com/send?phone=5513981260507&text=Olá,%20estou%20entrando%20em%20contato%20para%20obter%20informações%20sobre%20a%20Trasmontano%20Saúde."
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    <p className="quote-button-text">
                                        Quero uma cotação </p>
                                    <img
                                        src={btnwpp}
                                        alt="Ícone do WhatsApp">
                                    </img>
                                </a>
                            </div>
                        </div>

                        <div className="card bg-plans empresarial-card">
                            <h2 className="plan-name">LIFE 400<br />
                                <span>Linha <strong>Life</strong></span></h2>
                            <p className="plan-starting-price">a partir de:</p>
                            <h2 className="price">R$ 305,63</h2>
                            <p className="age-group"><strong>De 00 a 18 anos</strong></p>
                            <p className="plan__room"><strong>Enfermaria</strong><br />
                                (Em caso de Internação)</p>

                            <div className="hospitals">
                                <p>Hospital IGESP Unidade Paulista, Santana e Litoral</p>
                                <p>Santa Casa de Santo Amaro</p>
                                <p>Hospital Beneficência Portuguesa de Santos</p>
                                <p>Santa Casa de Santos</p>
                                <p>Hospital do Guarujá</p>
                                <p>E muito mais!</p>
                            </div>

                            <div className="quote-button-container">

                                <a
                                    className="quote-button"
                                    href="/rede"
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    <p className="quote-button-text">
                                        Rede de Atendimento</p>
                                    <img
                                        src={btndownload}
                                        alt="Ícone de Download">
                                    </img>
                                </a>

                                <a
                                    className="quote-button"
                                    href="https://api.whatsapp.com/send?phone=5513981260507&text=Olá,%20estou%20entrando%20em%20contato%20para%20obter%20informações%20sobre%20a%20Trasmontano%20Saúde."
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    <p className="quote-button-text">
                                        Quero uma cotação </p>
                                    <img
                                        src={btnwpp}
                                        alt="Ícone do WhatsApp">
                                    </img>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="card-planos__indfam">
                    <div className="card-planos__text card-planos__indfam__text">
                        <div>
                            <h3>Linha <strong>Mix</strong><br />
                                Plano de Saúde <strong>Empresarial</strong></h3>
                            <h4>Planos empresariais a partir de 02 vidas com cobertura<br />Hospitalar + Ambulatorial <strong>+ COM OBSTETRíCIA</strong></h4>
                        </div>
                        <p>
                            O <strong>Plano Mix Empresarial</strong> oferece flexibilidade e personalização no atendimento médico para sua empresa, permitindo a escolha de médicos e serviços conforme as necessidades de cada colaborador. Com tarifas vantajosas e acesso a uma ampla rede de saúde, é a solução ideal para garantir a saúde e o bem-estar da sua equipe.
                        </p>


                        <div className="div-separate">
                            <span className="style-div-separate"></span>
                        </div>
                    </div>

                    <div className="card-planos__cards">
                        <div className="card bg-plans indfam-card">
                            <h2 className="plan-name">MIX 300<br />
                                <span>Linha <strong>Mix Empresarial</strong></span></h2>
                            <p className="plan-starting-price">a partir de:</p>
                            <h2 className="price">R$ 180,29</h2>
                            <p className="age-group"><strong>De 00 a 18 anos</strong></p>
                            <p className="plan__room"><strong>Quarto Coletivo</strong><br />
                                (Em caso de Internação)</p>
                            <div className="hospitals">
                                <p>Hospital IGESP Unidade Paulista, Santana e Litoral</p>
                                <p>Santa Casa de Santo Amaro</p>
                                <p>Hospital Beneficência Portuguesa de Santos</p>
                                <p>Santa Casa de Santos</p>
                                <p>Hospital do Guarujá</p>
                                <p>E muito mais!</p>
                            </div>

                            <div className="quote-button-container">

                                <a
                                    className="quote-button"
                                    href="/rede"
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    <p className="quote-button-text">
                                        Rede de Atendimento</p>
                                    <img
                                        src={btndownload}
                                        alt="Ícone de Download">
                                    </img>
                                </a>

                                <a
                                    className="quote-button"
                                    href="https://api.whatsapp.com/send?phone=5513981260507&text=Olá,%20estou%20entrando%20em%20contato%20para%20obter%20informações%20sobre%20a%20Trasmontano%20Saúde."
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    <p className="quote-button-text">
                                        Quero uma cotação </p>
                                    <img
                                        src={btnwpp}
                                        alt="Ícone do WhatsApp">
                                    </img>
                                </a>
                            </div>
                        </div>

                        <div className="card bg-plans indfam-card">
                            <h2 className="plan-name">MIX SP 100<br />
                                <span>Linha <strong>Mix Empresarial</strong></span></h2>
                            <p className="plan-starting-price">a partir de:</p>
                            <h2 className="price">R$ 128,44</h2>
                            <p className="age-group"><strong>De 00 a 18 anos</strong></p>
                            <p className="plan__room"><strong>Enfermaria</strong><br />
                                (Em caso de Internação)</p>
                            <div className="hospitals">
                                <p>Hospital IGESP Unidade Paulista e Santana</p>
                                <p>Hospital Santa Virginia</p>
                                <p>Day Hospital de Ermelino Matarazzo</p>
                                <p>Hospital São Miguel</p>
                                <p>Santa Casa de Santo Amaro</p>
                                <p>E muito mais!</p>
                            </div>

                            <div className="quote-button-container">

                                <a
                                    className="quote-button"
                                    href="/rede"
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    <p className="quote-button-text">
                                        Rede de Atendimento</p>
                                    <img
                                        src={btndownload}
                                        alt="Ícone de Download">
                                    </img>
                                </a>

                                <a
                                    className="quote-button"
                                    href="https://api.whatsapp.com/send?phone=5513981260507&text=Olá,%20estou%20entrando%20em%20contato%20para%20obter%20informações%20sobre%20a%20Trasmontano%20Saúde."
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    <p className="quote-button-text">
                                        Quero uma cotação </p>
                                    <img
                                        src={btnwpp}
                                        alt="Ícone do WhatsApp">
                                    </img>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="card-planos__adesao">

                    <div className="card-planos__text">
                        <div>
                            <h3>Linha <strong>Care</strong><br />
                                Plano de Saúde <strong>Sênior</strong></h3>
                            <h4>Hospitalar + Ambulatorial <strong></strong></h4>
                        </div>
                        <p>
                            <strong>Especialmente desenvolvido para a melhor idade</strong>, este plano oferece condições exclusivas para garantir acesso a uma vasta rede de serviços médicos e hospitalares. Com cuidados personalizados e uma cobertura completa, a linha Care promove saúde, segurança e bem-estar, proporcionando tranquilidade para você aproveitar essa fase com qualidade de vida.
                        </p>

                        <div className="div-separate">
                            <span className="style-div-separate"></span>
                        </div>
                    </div>

                    <div className="card-planos__cards">
                        <div className="card bg-plans adesao-card">
                            <h2 className="plan-name">CARE 50<br />
                                <span>Linha <strong>Care</strong></span></h2>
                            <p className="plan-starting-price">a partir de:</p>
                            <h2 className="price">R$ 452,20</h2>
                            <p className="age-group"><strong>De 44 a 48 anos</strong></p>
                            <p className="plan__room"><strong>Enfermaria</strong><br />
                                (Em caso de Internação)</p>
                            <div className="hospitals">
                                <p>Hospital IGESP Unidade Paulista e Santana</p>
                                <p>Clinisul Serviço Médico da Zona Sul</p>
                                <p>Hospital Itaquera</p>
                                <p>IGESP Aeroporto</p>
                                <p>Trasmontano Saúde - Centro Médico Sede</p>
                                <p>E muito mais!</p>
                            </div>

                            <div className="quote-button-container">

                                <a
                                    className="quote-button"
                                    href="/rede"
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    <p className="quote-button-text">
                                        Rede de Atendimento</p>
                                    <img
                                        src={btndownload}
                                        alt="Ícone de Download">
                                    </img>
                                </a>

                                <a
                                    className="quote-button"
                                    href="https://api.whatsapp.com/send?phone=5513981260507&text=Olá,%20estou%20entrando%20em%20contato%20para%20obter%20informações%20sobre%20a%20Trasmontano%20Saúde."
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    <p className="quote-button-text">
                                        Quero uma cotação </p>
                                    <img
                                        src={btnwpp}
                                        alt="Ícone do WhatsApp">
                                    </img>
                                </a>
                            </div>
                        </div>

                        <div className="card bg-plans adesao-card">
                            <h2 className="plan-name">LITORAL 100<br />
                                <span>Linha <strong>Care</strong></span></h2>
                            <p className="plan-starting-price">a partir de:</p>
                            <h2 className="price">R$ 476,51</h2>
                            <p className="age-group"><strong>De 44 a 48 anos</strong></p>
                            <p className="plan__room"><strong>Enfermaria</strong><br />
                                (Em caso de Internação)</p>
                            <div className="hospitals">
                                <p>Hospital IGESP Unidade Litoral</p>
                                <p>Hospital Beneficência Portuguesa de Santos</p>
                                <p>Santa Casa de Santos</p>
                                <p>Hospital do Guarujá</p>
                                <p>APAS Hospital Santo Expedito</p>
                                <p>E muito mais!</p>
                            </div>

                            <div className="quote-button-container">

                                <a
                                    className="quote-button"
                                    href="/rede"
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    <p className="quote-button-text">
                                        Rede de Atendimento</p>
                                    <img
                                        src={btndownload}
                                        alt="Ícone de Download">
                                    </img>
                                </a>

                                <a
                                    className="quote-button"
                                    href="https://api.whatsapp.com/send?phone=5513981260507&text=Olá,%20estou%20entrando%20em%20contato%20para%20obter%20informações%20sobre%20a%20Trasmontano%20Saúde."
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    <p className="quote-button-text">
                                        Quero uma cotação </p>
                                    <img
                                        src={btnwpp}
                                        alt="Ícone do WhatsApp">
                                    </img>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>


            </div>

        </section >
    );
}

export default CardPlanos;
