import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import "./Cobertura.css";
import hospitalicon from "../../../../assets/img-main/hospital.png"

const Cobertura = () => {

    const location = useLocation();

    useEffect(() => {

        const hash = location.hash;
        if (hash) {
            const section = document.getElementById(hash.substring(1));
            if (section) {
                section.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [location]);


    return (
        <section className="cobertura__container" id="hospitais">

            <div className="cobertura__titulo">
                <h2>
                    UNIDADES PRÓPRIAS
                </h2>
            </div>


            <div className="rede__container">

                <div className="redes">
                    <div className="redes__info">
                        <img src={hospitalicon} alt="Ícone de um hospital" />
                        <div className="redes__info-localizacao">
                            <p>Sé</p>
                            <p>Rua Tabatinguera, 294<br /></p>
                        </div>

                    </div>

                    <div className="redes__info-texto">
                        <p>
                            Trasmontano Saúde<br />
                            Centro Médico Exclusivo
                        </p>

                        <div className="redes__info-servicos">
                            <p>Consulta</p>
                            <p>Coleta</p>
                        </div>

                        <p>São Paulo</p>
                    </div>
                </div>

                <div className="redes">
                    <div className="redes__info">
                        <img src={hospitalicon} alt="Ícone de um hospital" />
                        <div className="redes__info-localizacao">
                            <p>Lapa</p>
                            <p>Rua Barão de Jundiaí, 353<br /></p>
                        </div>
                    </div>

                    <div className="redes__info-texto">
                        <p>Trasmontano Saúde<br />Centro Médico Exclusivo</p>
                        <div className="redes__info-servicos">
                            <p>Consulta</p>
                            <p>Coleta</p>
                        </div>
                        <p>São Paulo</p>
                    </div>
                </div>

                <div className="redes">
                    <div className="redes__info">
                        <img src={hospitalicon} alt="Ícone de um hospital" />

                        <div className="redes__info-localizacao">
                            <p>Santana</p>
                            <p>Rua Duarte de Azevedo, 680<br /></p>
                        </div>
                    </div>

                    <div className="redes__info-texto">
                        <p>Trasmontano Saúde<br />Centro Médico Exclusivo</p>
                        <div className="redes__info-servicos">
                            <p>Consulta</p>
                        </div>
                        <p>São Paulo</p>
                    </div>
                </div>

                <div className="redes">
                    <div className="redes__info">
                        <img src={hospitalicon} alt="Ícone de um hospital" />
                        <div className="redes__info-localizacao">
                            <p>Tatuapé</p>
                            <p>Rua Fernandes Pinheiro, 102<br /></p>
                        </div>
                    </div>

                    <div className="redes__info-texto">
                        <p>Trasmontano Saúde<br />Centro Médico Exclusivo</p>
                        <div className="redes__info-servicos">
                            <p>Consulta</p>
                        </div>
                        <p>São Paulo</p>
                    </div>
                </div>

                <div className="redes">
                    <div className="redes__info">
                        <img src={hospitalicon} alt="Ícone de um hospital" />

                        <div className="redes__info-localizacao">
                            <p>Vila Matilde</p>
                            <p>Av. Aricanduva, 5555<br />Âncora 26, Alameda Itaquera 1º Piso</p>
                        </div>
                    </div>

                    <div className="redes__info-texto">
                        <p>Trasmontano Saúde<br />Centro Médico Exclusivo</p>
                        <div className="redes__info-servicos">
                            <p>Consulta</p>
                            <p>Coleta</p>
                        </div>
                        <p>São Paulo</p>
                    </div>
                </div>

                <div className="redes">
                    <div className="redes__info">
                        <img src={hospitalicon} alt="Ícone de um hospital" />

                        <div className="redes__info-localizacao">
                            <p>Jardim do Mar</p>
                            <p>Avenida Índico, 689<br /></p>
                        </div>
                    </div>

                    <div className="redes__info-texto">
                        <p>Trasmontano Saúde<br />Centro Médico Exclusivo</p>
                        <div className="redes__info-servicos">
                            <p>Consulta</p>
                        </div>
                        <p>São Bernardo do Campo</p>
                    </div>
                </div>

                <div className="redes">
                    <div className="redes__info">
                        <img src={hospitalicon} alt="Ícone de um hospital" />

                        <div className="redes__info-localizacao">
                            <p>Centro</p>
                            <p>Rua Jacob Emmerick, 921<br /></p>
                        </div>
                    </div>

                    <div className="redes__info-texto">
                        <p>Trasmontano Saúde<br />Centro Médico Exclusivo</p>
                        <div className="redes__info-servicos">
                            <p>Consulta</p>
                            <p>Coleta</p>
                        </div>
                        <p>São Vicente</p>
                    </div>
                </div>

                <div className="redes">
                    <div className="redes__info">
                        <img src={hospitalicon} alt="Ícone de um hospital" />

                        <div className="redes__info-localizacao">
                            <p>Campo Grande</p>
                            <p>Av. Bernardino de Campos, 348<br /></p>
                        </div>
                    </div>

                    <div className="redes__info-texto">
                        <p>Trasmontano Saúde<br />Centro Médico Exclusivo</p>
                        <div className="redes__info-servicos">
                            <p>Consulta</p>
                            <p>Coleta</p>
                        </div>
                        <p>Santos</p>
                    </div>
                </div>

                <div className="redes">
                    <div className="redes__info">
                        <img src={hospitalicon} alt="Ícone de um hospital" />

                        <div className="redes__info-localizacao">
                            <p>Aeroporto</p>
                            <p>Rua Baronesa de Bela Vista, 139<br /></p>
                        </div>
                    </div>

                    <div className="redes__info-texto">
                        <p>Unidades Ambulatoriais IGESP<br /></p>
                        <div className="redes__info-servicos">
                            <p>Consulta</p>
                        </div>
                        <p>São Paulo</p>
                    </div>
                </div>

                <div className="redes">
                    <div className="redes__info">
                        <img src={hospitalicon} alt="Ícone de um hospital" />
                        <div className="redes__info-localizacao">
                            <p>Vila Maia</p>
                            <p>Rua Montenegro, 25<br /></p>
                        </div>
                    </div>

                    <div className="redes__info-texto">
                        <p>Unidades Ambulatoriais IGESP<br /></p>
                        <div className="redes__info-servicos">
                            <p>Consulta</p>
                        </div>
                        <p>Guarujá</p>
                    </div>
                </div>

                <div className="redes">
                    <div className="redes__info">
                        <img src={hospitalicon} alt="Ícone de um hospital" />

                        <div className="redes__info-localizacao">
                            <p>Guilhermina</p>
                            <p>Av. Guadalajara, 453 - 7º Andar<br /></p>
                        </div>
                    </div>

                    <div className="redes__info-texto">
                        <p>Hospital IGESP Litoral</p>
                        <div className="redes__info-servicos">
                            <p>Consulta</p>
                            <p>Coleta</p>
                        </div>
                        <p>Praia Grande</p>
                    </div>
                </div>

                <div className="redes">
                    <div className="redes__info">
                        <img src={hospitalicon} alt="Ícone de um hospital" />
                        <div className="redes__info-localizacao">
                            <p>Sítio do Campo</p>
                            <p>Av. Ministro Marcos Freire, 800<br /></p>
                        </div>
                    </div>

                    <div className="redes__info-texto">
                        <p>Ambulatório Infantil IGESP</p>
                        <div className="redes__info-servicos">
                            <p>Consulta</p>
                        </div>
                        <p>Praia Grande</p>
                    </div>
                </div>

            </div>

        </section>
    )
}

export default Cobertura;