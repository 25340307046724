import './Associado.css';
import wpplogo from "../../../assets/img-main/wpplogo.png";

const Associado = () => {

    return (
        <section className='associado-container'>

            {/* Seção para quem não é cliente */}
            <div className='associado-contratar-container fundo-div'>
                <div className='associado-contratar'>
                    <p className='associado-titulo'><strong>Não sou Cliente</strong></p>
                    <br />
                    <p>Entre em contato para conhecer nossos planos e obter assistência na sua contratação.</p>
                    <br />
                    <br />
                </div>

                <div className='associado-contratar-meios'>
                    <div className="meios border-right">
                        <p className="meios-subtitulo">Telefone</p>
                        <br />
                        <a className='associado-phone'
                            href="https://api.whatsapp.com/send?phone=5513981260507&text=Olá,%20estou%20entrando%20em%20contato%20para%20obter%20informações%20sobre%20a%20Trasmontano%20Saúde."
                            target="_blank"
                            rel="noopener noreferrer">
                            {/* <i className="fas fa-phone fa-icon"></i> */}
                            <span>(13) 98126-0507</span>
                            <img src={wpplogo} alt="WhatsApp" className="wpp-logo" />
                        </a>
                    </div>

                    <div className="meios">
                        <p className="meios-subtitulo">Formulário</p>
                        <br />
                        <a className='associado-page' href='/cliente'>
                            <i className="fa-solid fa-file-signature fa-icon"></i>
                            <span>Seja Cliente</span>
                        </a>
                    </div>
                </div>
            </div>

            {/* Seção para quem já é cliente */}
            <div className='associado-client-service fundo-div'>
                <div className='client-service-header'>
                    <p className='associado-titulo'><strong>Já sou Cliente</strong></p>
                    <br />
                    <p>Ligue para informações sobre seu plano, dúvidas ou orientações.</p>
                    <br />
                    <br />
                </div>

                <div className='client-service-locations'>
                    <div className='location border-right'>
                        <p>São Paulo</p>
                        <br />
                        <h5 className='location-phone'>
                            <i className="fas fa-phone fa-icon"></i>
                            <span>(11) 3293-1515</span>
                            <img src={wpplogo} alt="WhatsApp" className="wpp-logo" />
                        </h5>
                    </div>

                    <div className='location'>
                        <p>Litoral</p>
                        <br />
                        <h5 className='location-phone-litoral'>
                            <i className="fas fa-phone fa-icon"></i>
                            <span>(13) 3308-3020</span>
                        </h5>
                    </div>
                </div>
            </div>

            {/* Seção institucional */}
            <div className='associado-institutional'>
                <div className='institutional-header fundo-div'>
                    <p className='associado-titulo'><strong>Institucional, reclamações ou cancelamentos</strong></p>
                    <br />
                    <br />
                    <div className="institutional-phone">
                        <i className="fas fa-phone fa-icon"></i>
                        <span>0800 1605 320</span>
                    </div>
                </div>

                {/* Atendimento presencial com link para a seção de hospitais */}
                <div className='associado-in-person fundo-div'>
                    <p className='associado-titulo'><strong>Atendimento presencial</strong></p>
                    <br />
                    <br />
                    <p>Disponível nos Centros Médicos <a href="/sobre#hospitais">Sede (São Paulo)</a> e <a href="/sobre#hospitais">São Vicente</a>.</p>
                </div>
            </div>

        </section>
    );
};

export default Associado;
