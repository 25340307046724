import "./Footer.css";
// import footerinsta from "../../assets/img-footer/icon-insta.png";
// import footerfb from "../../assets/img-footer/icon-fb.png";
import footerwpp from "../../assets/img-footer/icon-wpp.png";

const Footer = () => {
    return (
        <footer className="footer">
            <div className="redes__footer">
                <div className="redes__itens">
                    {/* <a className="redes__item" href="" target="_blank" rel="noopener noreferrer">
                        <img src={footerfb} alt="ícone logo Facebook" />
                    </a>

                    <a className="redes__item" href="" target="_blank" rel="noopener noreferrer">
                        <img src={footerinsta} alt="ícone logo Instagram" />
                    </a> */}

                    <a className="redes__item" href="https://api.whatsapp.com/send?phone=5513981260507&text=Olá,%20estou%20entrando%20em%20contato%20para%20obter%20informações%20sobre%20a%20Trasmontano%20Saúde." target="_blank" rel="noopener noreferrer">
                        <img src={footerwpp} alt="ícone logo WhatsApp" />
                    </a>
                </div>
            </div>


            <div className="copyright">
                <p>&copy; 2024 - <a href="http://trasmontanosaude.com" className="footer-link"><span>TrasmontanoSaude.com</span></a> - Todos os direitos reservados.</p>
                <p>Desenvolvido por: <span>Ferrarez Dev</span></p>
            </div>

        </footer>
    )
}

export default Footer;
