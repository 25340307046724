import React from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';

import './Client.css';

import logo2 from "../../../assets/logo2.png";
import cliente_info from "../../../assets/img-cliente/cliente-info.png"

import Form from './form/Form.js';
import usePageTracking from '../../../hooks/usePageTracking.js';


function Client() {
  usePageTracking();

  const navigate = useNavigate();

  const handleFormSubmitted = () => {
    navigate('/confirmacao');
  };

  return (
    <div className="contact">
      <Helmet>
        <title>Seja Cliente | Trasmontano Saúde</title>
        <meta name="description" content="Torne-se cliente da Trasmontano Saúde e tenha acesso a um atendimento médico de qualidade com cobertura completa. Contrate seu plano de saúde de forma rápida e prática." />

      </Helmet>

      <div className="contact__info">

        <img className="contact__logo" src={logo2} alt="Logo da empresa" />

        <div className='contact__subtitle__container'>

          <p className="contact__subtitle">Conheça <span>nossos planos</span> e escolha o ideal para <span>você</span> e sua <span>família</span> ou para sua <span>empresa</span>.</p>
          <p className="contact__p">Economize até 40% utilizando <br />seu CNPJ a partir de 2 vidas!</p>

        </div>

        <img className='contact__cliente__img' src={cliente_info} alt='Imagem de uma família' />

      </div>

      <div className='form_grid'>

        <Form onFormSubmitted={handleFormSubmitted} />

      </div>

    </div>
  );
}

export default Client;
