import './Planos.css'
import React from 'react';
// , { useState }
import { Helmet } from 'react-helmet';

import usePageTracking from '../../../hooks/usePageTracking.js';
import CardPlanos from './CardPlanos/CardPlanos.js';
// import CopModal from './CopModal/CopModal.js';

const Planos = () => {


    // const [showModal, setShowModal] = useState(false);

    // const openModal = () => setShowModal(true);
    // const closeModal = () => setShowModal(false);

    usePageTracking();

    return (
        <section className="page__plans-section">
            <Helmet>
                <title>Planos de Saúde | Trasmontano Saúde</title>
                <meta name="description"
                    content="Descubra os planos de saúde da Trasmontano Saúde. Oferecemos cobertura completa, atendimento de qualidade e soluções para diferentes perfis, incluindo planos individuais, familiares, idosos e empresariais." />
            </Helmet>

            <div className="page__plans-container">
                <div className="page__plans-text">
                    <div className="page__plans-title">
                        <h2><strong>Valores e Coberturas</strong> de alguns dos Planos de Saúde<br /> <strong>Trasmontano</strong></h2>
                    </div>
                    <div className="div-separate"></div>
                </div>


                <CardPlanos />

                {/* <div className='modal__container'>
                    <p>
                        Clique no botão a seguir para visualizar a tabela de coparticipação com os valores máximos de terapias, procedimentos e materiais importados.
                    </p>
                    <button onClick={openModal}>
                        Ver Tabela de Coparticipação
                    </button>
                </div>

                <CopModal showModal={showModal} closeModal={closeModal} /> */}

            </div >

        </section >

    )
}

export default Planos;