import './Contact.css';
import { Helmet } from 'react-helmet';

import Channel from './channel/Channel';
import Questions from './questions/Questions';
import usePageTracking from '../../../hooks/usePageTracking.js';

const Contact = () => {

    usePageTracking();

    return (
        <section className="atendimento">

            <Helmet>
                <title>Dúvidas Frequentes | Trasmontano Saúde</title>
                <meta name="description"
                    content="Encontre respostas para as dúvidas mais comuns sobre os planos de saúde da Trasmontano Saúde. Saiba tudo sobre carências, coberturas e benefícios dos nossos planos de saúde." />
            </Helmet>

            <div className="atendimento__title">
                <h2>
                    CANAIS DE ATENDIMENDO DIRECIONADOS ÀS VENDAS
                </h2>
            </div>

            <Channel />
            <Questions />

        </section>
    )
}

export default Contact;