import React from 'react';
import { Helmet } from 'react-helmet';

import './Rede.css';

import CityCard from './CityCard/CityCard';


const Redes = () => {

    const cities = [
        { nome: 'Smart SP', downloadUrl: '/assets/download/Rede-Linha-Smart-SP.pdf' },
        { nome: 'Smart 200', downloadUrl: '/assets/downloads/Rede-Linha-Smart-200.pdf' },
        { nome: 'Life Litoral 100', downloadUrl: '/assets/downloads/Rede-Linha-Life-Litoral-100.pdf' },
        { nome: 'Life 400', downloadUrl: '/assets/downloads/Rede-Linha-Life-400.pdf' },
        { nome: 'Mix 300', downloadUrl: '/assets/downloads/Rede-Linha-Mix-300.pdf' },
        { nome: 'Mix SP 100', downloadUrl: '/assets/downloads/Rede-Linha-Mix-SP-100.pdf' },
        { nome: 'Care 50', downloadUrl: '/assets/downloads/Rede-Linha-Care-50.pdf' },
        { nome: 'Care Litoral 100', downloadUrl: '/assets/downloads/Rede-Linha-Care-Litoral-100.pdf' },
    ];

    return (
        <section className='redes-container'>
            <Helmet>
                <title>Rede Credenciada | Trasmontano Saúde</title>
                <meta
                    name="description"
                    content="Explore a rede credenciada da Trasmontano Saúde. Visualize ou faça o download das informações sobre profissionais e estabelecimentos médicos em sua região."
                />
            </Helmet>
            <div className='redes-description'>
                <h1>
                    CONHEÇA NOSSA <span>AMPLA REDE DE ATENDIMENTO</span>
                </h1>
                <p>
                    Conheça a nossa rede de atendimento, selecionada para oferecer a <span>melhor cobertura</span> de serviços médicos para você. Clique em "<span>Download da Rede</span>" e faça o <span>download</span> para ter sempre à mão todas as informações sobre os profissionais e estabelecimentos que cuidam da sua saúde.
                </p>
                {/* <p className='note'><span>*</span>Em dispositivos móveis (celulares), será possível apenas realizar o download da rede credenciada.</p> */}

                <div className="div-separate">
                    <span className="style-div-separate"></span>
                </div>

            </div>

            <div className="redes-page">
                {cities.map((city, index) => (
                    <CityCard key={index} city={city} />
                ))}
            </div>
        </section>
    );
};

export default Redes;
