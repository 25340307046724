import React from 'react';
import './CityCard.css';

import btndownload from '../../../../assets/img-main/downloadicon.png'

const CityCard = ({ city }) => {
    return (
        <div className="city-card">
            <h3>Rede de Atendimento da<br />Linha <span>{city.nome}</span></h3>
            <a href={city.downloadUrl} download>
                <button>Download da Rede<img
                    src={btndownload}
                    alt='ícone de download'
                />
                </button>

            </a>
        </div>
    );
};

export default CityCard;
